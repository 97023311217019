.about-us {
    @apply w-full min-h-screen box-border text-black bg-white px-4 py-10 grid justify-center items-center gap-5;
    @apply md:px-8 md:py-[57px] md:gap-6;
    @apply xl:grid-cols-[678px_auto] xl:grid-rows-[140px_auto] xl:px-16 xl:py-14;
    @apply 2xl:grid-cols-[738px_auto] 2xl:grid-rows-[240px_auto] 2xl:px-20 2xl:py-14;
}

.about-us .about-content {
    @apply w-[300px] xs:w-[328px] text-black;
    @apply md:w-[738px] xl:w-[580px] 2xl:w-[738px];
}

.about-us .about-content h1{
    @apply text-[45px] leading-[52px] font-normal mb-[28px];
    @apply md:text-[48px] md:leading-[64px] md:mb-3;
    @apply 2xl:text-[80px] 2xl:leading-[100px];
}

.about-us .about-content p{
    @apply text-lg font-normal;
}

.about-items-list{
    @apply grid gap-5 justify-center;
    @apply md:grid-cols-2 md:justify-start;
}

.about-items-list .items{
    @apply w-[300px] xs:w-[305px] grid gap-3 text-center 2xl:text-start;
}

.about-items-list .items img{
    @apply mx-auto 2xl:mx-0;
}

.about-items-list .items .text p{
    @apply text-base font-normal;
}

.about-us .about-image {
    @apply lg:row-span-2
}

.about-us .about-image img{
    @apply w-[320px] h-[408.28px];
    @apply md:w-[770px] md:h-[982px];
    @apply xl:w-[522px] xl:h-[666px];
    @apply 2xl:w-[522px] 2xl:h-[666px];
}