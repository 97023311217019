nav{
    @apply w-full h-[72px] box-border bg-sys-dark-background border-gray-200 py-0 lg:px-3 sticky top-0 z-10;
    @apply 2xl:h-[100px];
}


ul.nav-items{
    @apply font-medium flex flex-col px-0 py-6 rounded-lg bg-gradient-surfaces-dark-high-contrast-surface3;
    @apply lg:flex-row lg:gap-[13px] rtl:space-x-reverse lg:mt-0 lg:py-0 lg:border-0;
}

ul.nav-items li a{
    @apply block text-[15px] font-normal px-4 py-3 lg:py-2 lg:px-3 text-white rounded lg:p-0;
}