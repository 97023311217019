.location-section{
    @apply w-full bg-sys-dark-surface-container-lowest px-4 py-10;
    @apply md:px-8 md:py-10;
    @apply 2xl:py-[60px] 2xl:px-20;
}

.location-section .top h1{
    @apply text-[36px] leading-[48px] font-normal text-start text-sys-dark-on-background mb-4;
    @apply md:text-center;
    @apply lg:text-[50px] lg:leading-[60px] lg:text-center;
    @apply 2xl:text-[80px] 2xl:leading-[100px] 2xl:text-center 2xl:mb-8;
}

.location-section .location-content{
    @apply flex flex-col justify-center items-start gap-4;
    @apply md:flex-row md:items-center md:justify-start md:gap-4;
    @apply lg:flex-row lg:justify-center lg:items-center lg:gap-[215px];
}

.location-section .location-content .map{
    @apply max-md:mx-auto;
}

.location-section .location-content .map iframe{
    @apply w-[300px] xs:w-[90vw] h-[208.25px] bg-slate-100 border-0;
    @apply md:w-[511.5px] md:h-[324.76px];
    @apply lg:w-[500px] lg:h-[310px];
    @apply 2xl:w-[630px] 2xl:h-[400px];
}

.location-section .location-content .address h2{
    @apply text-2xl font-normal text-sys-dark-on-background pb-4; 
    @apply 2xl:text-[57px] 2xl:leading-[64px];
}

.location-section .location-content .address h5{
    @apply text-base 2xl:text-2xl font-normal text-sys-dark-on-background;
}