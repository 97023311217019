.solution-section{
    @apply w-full h-fit bg-sys-dark-surface-container-lowest flex flex-col gap-12 px-4 py-10;
    @apply 2xl:gap-[60px] 2xl:pt-[60px] 2xl:pb-10 2xl:px-20;
}

.solution-section .top{
    @apply text-start md:text-center text-sys-dark-on-background grid gap-2 2xl:gap-3;
}

.solution-section .top h1{
    @apply text-[34px] leading-[39.1px] font-normal;
    @apply text-[48px] leading-[55.2px];
    @apply 2xl:text-[80px] 2xl:leading-[100px];
}

.solution-section .top p{
    @apply text-lg font-normal;
    @apply 2xl:text-[22px] 2xl:leading-[28px];
}

.solution-section .solution-content{
    @apply flex flex-col justify-center items-center gap-8;
    @apply lg:flex-row lg:justify-around;
}

.solution-section .solution-content .solution-item{
    @apply flex flex-col items-center justify-center gap-4 text-center px-1 py-0;
}

.solution-section .solution-content .solution-item .text-content h4{
    @apply text-2xl font-normal text-sys-dark-on-background;
}

.solution-section .solution-content .solution-item .text-content p{
    @apply text-base font-normal text-sys-dark-on-background;
}