.our-services-section{
    @apply w-full min-h-screen bg-sys-dark-background flex flex-col gap-4 justify-center items-start px-4 py-10;
    @apply md:gap-8 md:px-8 md:py-10;
    @apply xl:items-center;
    @apply 2xl:gap-[72px] 2xl:px-[188px] 2xl:py-16;
}

.our-services-section .top{
    @apply text-start text-sys-dark-on-background grid gap-3;
    @apply xl:text-center xl:gap-4;
}

.our-services-section .top h1{
    @apply text-[48px] leading-[64px] font-normal;
    @apply 2xl:text-[80px] 2xl:leading-[100px];
}

.our-services-section .top p{
    @apply text-lg font-normal;
    @apply 2xl:text-[22px] 2xl:leading-[28px];
}

.our-services-section .service-content{
    @apply grid gap-6;
    @apply md:grid-cols-2 md:gap-x-4 md:gap-y-8;
    @apply xl:gap-x-5 xl:gap-y-10;
}

.our-services-section .service-content .content-item{
    @apply xl:w-[522px];
}

.our-services-section .service-content .content-item .details h4{
    @apply py-2 text-2xl font-bold 2xl:font-normal text-sys-dark-on-background;
}

.our-services-section .service-content .content-item .details p{
    @apply text-sm 2xl:text-base font-normal text-sys-dark-on-background;
}