.contact-section{
    @apply w-full h-fit flex flex-col gap-8 items-start md:items-center bg-sys-dark-background px-4 py-10;
    @apply lg:px-20 lg:py-[51px] lg:gap-20;
}

.contact-section .title{
    @apply flex flex-col gap-3 text-start md:text-center;
}

.contact-section .title h1{
    @apply text-4xl font-normal text-sys-dark-on-background;
    @apply 2xl:text-[57px] 2xl:leading-[64px];
}

.contact-section .title p{
    @apply text-lg font-normal text-sys-dark-on-background;
    @apply 2xl:text-[22px];
}

.contact-form{
    @apply w-full flex flex-col gap-8 xl:w-auto;
}

.form-content{
    @apply w-full flex flex-col xl:flex-row gap-8 xl:w-auto xl:gap-[120px];
}

.form-item{
    @apply w-full grid gap-3;
}

.form-content .left-content{
    @apply w-full xl:w-[522px] flex flex-col gap-8;
}

label.form-label{
    @apply text-[22px] leading-[28px] font-normal text-white;
}



input.form-input{
    @apply w-full rounded border border-sys-dark-outline outline-none p-[15.5px_20px_15.5px_12px] text-sm font-normal text-sys-dark-on-background bg-transparent;
}


textarea.form-textarea{
    @apply w-full h-[163px] rounded-xl border border-sys-dark-outline outline-none px-6 py-5 text-base font-normal text-sys-dark-on-background bg-state-layers-light-surfaceContainerHighest-opacity-0.08;
    @apply xl:w-[522px] xl:h-[296px];
}

form.contact-form button{
    @apply w-full lg:w-[522px] lg:mx-auto h-[76px] px-[10px] py-6 text-[22px] text-sys-dark-on-background;
}

form.contact-form button:hover{
    @apply bg-sys-dark-primary-container;
}