

.hero-section{
    background: url('../../assets/hero-bg-layer.png');
    background-size: cover;
    background-repeat: no-repeat;
    @apply h-screen bg-[-660px_center] flex justify-start items-end;
    @apply md:bg-center;
    @apply 2xl:bg-center 2xl:min-h-screen;
}

.hero-section .content{
    @apply w-[328px] mx-4 mb-[88px] flex flex-col gap-3;
    @apply md:w-full md:mx-4 md:mb-16 md:gap-6;
    @apply xl:w-[1008px] xl:ml-20 xl:mb-[103px] xl:gap-6;
}

.hero-section .content h1{
    @apply text-[42px] leading-[48.3px] font-bold text-white;
    @apply md:text-[62px] md:leading-[80px];
    @apply lmd:text-[72px] lmd:leading-[96px];
    @apply 2xl:text-[80px];
}

.hero-section .content h4{
    @apply w-full text-xl text-white font-normal;
    @apply md:text-[22px] lmd:text-2xl;
    @apply 2xl:w-[738px] 2xl:text-[28px] 2xl:leading-[36px];
}

.hero-section .content a{
    @apply block w-[156px] h-[52px] rounded-3xl px-8 py-4 bg-gradient-hero-button text-base text-center text-white;
}