footer{
    @apply w-full bg-gradient-surfaces-dark-medium-contrast-surface5 px-4 py-[49px];
    @apply lg:pr-[89px] lg:pb-[49px] lg:pl-[71px];
    @apply 2xl:pt-[97px];
}

footer .footer-contents{
    @apply flex flex-col justify-center items-center gap-[54px];
    @apply lg:flex-row;
}

footer .footer-contents .logo{
    @apply flex flex-col items-center gap-6;
}

footer .footer-contents .logo img{
    @apply w-[100px] h-[100px];
}

footer .footer-contents .logo .social{
    @apply flex gap-4;
}

footer .footer-contents .logo .social span{
    @apply text-sys-dark-primary text-2xl;
}

footer h1{
    @apply my-[55px] text-[44px] xs:text-[50.78px] text-center leading-[50.78px] font-bold uppercase bg-gradient-footer-name text-transparent bg-clip-text;
    @apply md:text-[110.31px] md:leading-[110.31px];
    @apply lmd:text-[120.31px] lmd:leading-[120.31px];
    @apply lg:text-[100px] lg:leading-[100px];
    @apply 2xl:text-[200px] 2xl:leading-[200px];
}

footer p.copy{
    @apply text-base font-bold text-white text-center;
}